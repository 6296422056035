import React, { useRef, useState, useEffect } from 'react';

const Filters = ({ filter, handleFilterChange }) => {
  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  // Touch and mouse event handlers
  const handleDragStart = (e) => {
    setIsDragging(true);
    const pageX = e.pageX || e.touches[0].pageX;
    setStartX(pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleDragMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const pageX = e.pageX || (e.touches && e.touches[0].pageX);
    if (!pageX) return;
    
    const x = pageX - scrollContainerRef.current.offsetLeft;
    const scroll = scrollLeft - (x - startX);
    scrollContainerRef.current.scrollLeft = scroll;
  };

  // Add and remove event listeners
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    // Mouse events
    container.addEventListener('mousemove', handleDragMove);
    window.addEventListener('mouseup', handleDragEnd);

    return () => {
      container.removeEventListener('mousemove', handleDragMove);
      window.removeEventListener('mouseup', handleDragEnd);
    };
  }, [isDragging, startX, scrollLeft]);

  return (
    <div
      ref={scrollContainerRef}
      style={{
        display: "flex",
        padding: "5px 20px",
        position: "sticky",
        top: "0px",
        background: "white",
        zIndex: "99",
        width: "100%",
        overflowX: "auto",
        WebkitOverflowScrolling: "touch",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        cursor: isDragging ? "grabbing" : "grab",
        userSelect: "none",
        marginTop: "5px"
      }}
      className="filter-buttons-container"
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      onTouchStart={handleDragStart}
      onTouchEnd={handleDragEnd}
      onTouchMove={handleDragMove}
    >
      <style>
        {`
          .filter-buttons-container::-webkit-scrollbar {
            display: none;
          }
          .filter-button {
            transition: background-color 0.3s ease, color 0.3s ease;
          }
        `}
      </style>
      
      <div style={{ display: "flex", minWidth: "min-content", paddingBottom: "5px" }}>
        <button
          className="filter-button"
          onClick={() => handleFilterChange("all")}
          style={{
            background: filter === "all" ? "var(--Light-blue, #EAF7FF)" : "#F2F2F2",
            color: filter === "all" ? "var(--Blue, #1764D1)" : "var(--Grey, #494545)",
            border: "none",
            padding: "5px 15px",
            height: "30px",
            minWidth: "80px",
            borderRadius: "50px",
            marginRight: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "nowrap",
            flexShrink: 0,
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
          }}
        >
          All
        </button>
        <button
          className="filter-button"
          onClick={() => handleFilterChange("unread")}
          style={{
            background: filter === "unread" ? "var(--Light-blue, #EAF7FF)" : "#F2F2F2",
            color: filter === "unread" ? "var(--Blue, #1764D1)" : "var(--Grey, #494545)",
            border: "none",
            padding: "5px 15px",
            height: "30px",
            minWidth: "80px",
            marginRight: "10px",
            borderRadius: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "nowrap",
            flexShrink: 0,
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
          }}
        >
          Unread
        </button>
        <button
          className="filter-button"
          onClick={() => handleFilterChange("transferred_to_human")}
          style={{
            background: filter === "transferred_to_human" ? "var(--Light-blue, #EAF7FF)" : "#F2F2F2",
            color: filter === "transferred_to_human" ? "var(--Blue, #1764D1)" : "var(--Grey, #494545)",
            border: "none",
            padding: "5px 15px",
            height: "30px",
            minWidth: "180px",
            borderRadius: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "nowrap",
            flexShrink: 0,
            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
          }}
        >
          Transferred to Human
        </button>
      </div>
    </div>
  );
};

export default Filters;